import { useState } from 'react';
import { useOps } from './store';

export const OperationsSimulator = () => {
  return (
    <>
      <h1>Eurosim 2.0 - Operations Simulator</h1>
      <h2>Production Plan</h2>
      <ProductionPlanSetup planId="1" />
      <ProductionPlanInfo planId="1" />
    </>
  );
};

const ProductionPlanSetup: React.FC<{ planId: string }> = props => {
  const SKUs = useOps(s => s.listPlannedSKUs(props.planId));

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Volumes</th>
          </tr>
        </thead>
        <tbody>
          {SKUs.map((sku, i) => (
            <PlanEntry key={i} planId={props.planId} sku={sku} />
          ))}
          <EmptyPlanEntry planId={props.planId} />
        </tbody>
      </table>
    </>
  );
};

const PlanEntry: React.FC<{ planId: string; sku: string }> = props => {
  const volumes = useOps(s => s.readPlannedVolumes(props.planId, props.sku));
  const plan = useOps(s => s.plan);
  const unPlan = useOps(s => s.unPlan);

  const [value, setValue] = useState(volumes.toString());

  return (
    <>
      <tr>
        <td>{props.sku}</td>
        <td>
          <input value={value} onChange={e => setValue(e.target.value)} />
        </td>
        <td>
          <button
            disabled={volumes === Number(value)}
            onClick={() => plan(props.planId, props.sku, Number(value))}
          >
            Save
          </button>

          <button onClick={() => unPlan(props.planId, props.sku)}>X</button>
        </td>
      </tr>
    </>
  );
};

const EmptyPlanEntry: React.FC<{ planId: string }> = props => {
  const plan = useOps(s => s.plan);

  const [sku, setSku] = useState('');
  const [value, setValue] = useState('');

  return (
    <>
      <tr>
        <td>
          <input value={sku} onChange={e => setSku(e.target.value)} />
        </td>
        <td>
          <input value={value} onChange={e => setValue(e.target.value)} />
        </td>
        <td>
          <button
            onClick={() => {
              plan(props.planId, sku, Number(value));
              setSku('');
              setValue('');
            }}
          >
            Done
          </button>
        </td>
      </tr>
    </>
  );
};

const ProductionPlanInfo: React.FC<{ planId: string }> = props => {
  const totalCapacity = useOps(s => s.readTotalCapacity(props.planId));
  const setup = useOps(s => s.readSetupInefficiency(props.planId));
  const learning = useOps(s => s.readLearningInefficiency(props.planId));
  const available = useOps(s => s.readAvailableCapacity(props.planId));
  const overtime = useOps(s => s.readNeededOverTime(props.planId));
  const isDoable = useOps(s => s.isPlanDoable(props.planId));
  const volumes = useOps(s => s.evaluateProductionVolumes(props.planId));

  return (
    <table>
      <tbody>
        <tr>
          <th>Total Capacity</th>
          <td>{totalCapacity}</td>
        </tr>
        <tr>
          <th>Setup Inefficiency</th>
          <td>{(setup * 100).toFixed(2)} %</td>
        </tr>
        <tr>
          <th>Learning Inefficiency</th>
          <td>{(learning * 100).toFixed(2)} %</td>
        </tr>
        <tr>
          <th>Available Capacity</th>
          <td>{available}</td>
        </tr>
        <tr>
          <th>Total Volumes to be produces</th>
          <td>{volumes}</td>
        </tr>
        <tr>
          <th>Overtime (in units)</th>
          <td>{overtime}</td>
        </tr>
        <tr>
          <th>is plan doable?</th>
          <td>{isDoable ? 'Yes' : 'No'}</td>
        </tr>
      </tbody>
    </table>
  );
};
