import React from 'react';
import { by } from '../../../../lib/say-it';
import { useOffers } from '../store';

export const CountryMarketTraitsInput: React.FC<{
  countryId: string;
}> = props => {
  const incrementSegmentSize = useOffers(s => s.incrementSegmentSize);
  const decrementSegmentSize = useOffers(s => s.decrementSegmentSize);
  const segments = useOffers(s => s.segmentSizeByCountry(props.countryId));

  return (
    <div>
      <table style={{ width: 350 }}>
        <thead>
          <tr>
            <th>seg</th>
            <th>size (kU)</th>
            <th>%</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {segments
            .sort(by('id', (a, b) => a.length - b.length))
            .map((s, i) => (
              <tr key={i}>
                <td>{s.id}</td>
                <td>{(s.size / 1000).toFixed(0)}</td>
                <td>{(s.relativeSize * 100).toFixed(1)}</td>
                <td>
                  <button
                    onClick={() => incrementSegmentSize(props.countryId, s.id)}
                  >
                    +
                  </button>
                  <button
                    onClick={() => decrementSegmentSize(props.countryId, s.id)}
                  >
                    -
                  </button>
                  100 kU
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
