import React from 'react';
import { CountrySummaryReport } from '../../store/concepts/eurosim';
import { percent } from '../layout/formatters';
import { Interaction } from '../layout/interaction';
import { Param } from '../layout/param';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { FeatureSelectionTable } from '../product/feature-selection-table';
import { VariableCostReport } from '../product/report.variable-cost';
import { Term } from '../term';

const report: CountrySummaryReport = {
  countries: [
    { name: 'Germania', numberOfFirms: 2, taxRate: 0.19 },
    { name: 'Italia', numberOfFirms: 2, taxRate: 0.26 },
    { name: 'Francia', numberOfFirms: 2, taxRate: 0.26 },
    { name: 'Polonia', numberOfFirms: 1, taxRate: 0.12 },
  ],
};

export const KnowTheGeography = () => (
  <>
    <SectionTitle id="know-the-geography">Conosci la Geografia</SectionTitle>

    <p>
      Il mondo simulato presenta <Param>3</Param> <Term lemma="Paesi attivi" />{' '}
      descritti nella tabella che segue.
    </p>

    <Report>
      <h4 id="active-countries">Tabella dei Paesi Attivi</h4>
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr
            style={{
              verticalAlign: 'top',
              lineHeight: '1em',
            }}
          >
            <>
              <th style={{ width: '10%' }}>Paese</th>
              <th style={{ width: '10%' }}># Aziende</th>
              <th style={{ width: '10%', textAlign: 'right' }}>
                Aliquota Tassazione
              </th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.countries.map((info, i) => (
            <tr key={i} style={{ background: i % 2 ? '#eee' : 'white' }}>
              <td>{info.name}</td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {info.numberOfFirms}
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {percent(info.taxRate)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <span>Tabella di comparazione indice di costo per paese</span>
    </Report>
  </>
);
