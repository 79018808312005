import create, { GetState, SetState } from 'zustand';
import * as Logger from './factories/logger';
import * as Components from './factories/components';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Logger.create(set, get),
  ...Components.create(set, get),
});

export const useFanes = create(createRootSlice);
