import { CSSProperties, Fragment } from 'react';
import { toSum } from '../../../../lib/say-it';
import { evaluateMarketPreference } from '../model';
import { useOffers } from '../store';
import { CountryMarketTraitsInput } from './country-market-traits-input';
import { PartialUtilityCoefficientSetup } from './partial-utilities-coeff-setup';

const centered: { style: CSSProperties } = { style: { textAlign: 'center' } };

export const CountryMarketTable: React.FC<{ country: string }> = props => {
  const offers = useOffers(s => s.offersToEvaluate());
  const segments = useOffers(s => s.segmentSizeByCountry(props.country));
  const withdrawBrand = useOffers(s => s.withdrawBrand);
  const featuresPartialUtility = useOffers(s =>
    s.featurePartialUtilityInCountry(props.country)
  );

  return (
    <>
      <h4>Market Size and Segment Distribution</h4>
      <CountryMarketTraitsInput countryId={props.country} />

      <h4>Market Preference Tuning</h4>
      <PartialUtilityCoefficientSetup countryId={props.country} />

      <h4>Market Response</h4>
      <table style={{ width: 1500 }} cellPadding={2} cellSpacing={5}>
        <thead>
          <tr>
            <th {...centered} colSpan={8}>
              Products
            </th>
            <th {...centered} colSpan={segments.length * 4 + 1}>
              Segments
            </th>
            <th colSpan={2} rowSpan={2}>
              Market Share
            </th>
          </tr>
          <tr>
            <th colSpan={8}></th>
            <th {...centered} colSpan={segments.length * 2}>
              Market Preferences
            </th>
            <th {...centered} colSpan={segments.length}>
              Segment Shares
            </th>
            <th {...centered} colSpan={segments.length}>
              Volumes
            </th>
          </tr>
          <tr>
            <th>Actions</th>
            <th>Brand Name</th>
            <th>Cost</th>
            <th>Price</th>
            <th>H.Price</th>
            <th>Product Features</th>
            <th>B.A. Inv.</th>
            <th>B.A. Ind.</th>
            {segments.map((seg, j) => (
              <th key={j} {...centered} colSpan={2}>
                {seg.id}
              </th>
            ))}
            {segments.map((seg, j) => (
              <th key={j} {...centered}>
                {seg.id}
              </th>
            ))}
            {segments.map((seg, j) => (
              <th key={j} {...centered}>
                {seg.id}
              </th>
            ))}
            <th>Revenues</th>
            <th {...centered}>U</th>
            <th {...centered}>€</th>
          </tr>
        </thead>

        <tbody>
          {evaluateMarketPreference(featuresPartialUtility)(() => segments)(
            () => offers
          ).map((e, i) => (
            <tr key={i}>
              <td>
                <button onClick={() => withdrawBrand(e.offer.brand)}>x</button>
              </td>
              <td>{e.offer.brand}</td>
              <td>
                {e.offer.features.map(featureCost).reduce(toSum(), 0) + 60}
              </td>
              <td>{e.offer.price}</td>
              <td>{e.offer.historicalPrice}</td>
              <td>{e.offer.features.join(', ')}</td>
              <td>{offers[i].brandAwarenessInvestment / 1000} K€</td>
              <td>{(e.offer.brandAwarenessIndex * 100).toFixed(2)} %</td>
              {segments.map((seg, j) => (
                <Fragment key={j}>
                  <td style={{ fontSize: '80%', textAlign: 'right' }}>
                    {e.segments[seg.id].performance.toFixed(2)}
                  </td>
                  <td
                    style={{
                      background: 'yellow',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {(e.segments[seg.id].preference * 100).toFixed(2)} %
                  </td>
                </Fragment>
              ))}
              {segments.map((seg, j) => (
                <Fragment key={j}>
                  <td
                    style={{
                      // background: 'yellow',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {(e.segments[seg.id].share * 100).toFixed(2)} %
                  </td>
                </Fragment>
              ))}
              {segments.map((seg, j) => (
                <Fragment key={j}>
                  <td
                    style={{
                      background: 'yellow',
                      fontWeight: 'bold',
                      textAlign: 'right',
                    }}
                  >
                    {(e.segments[seg.id].volumes * 0.001).toFixed(0)} Ku
                  </td>
                </Fragment>
              ))}
              <td style={{ textAlign: 'right' }}>
                {(e.revenues * (1 / 1_000_000)).toFixed(2)} M€
              </td>
              <td style={{ textAlign: 'right' }}>
                {(e.shareOfRevenues * 100).toFixed(1)} %
              </td>
              <td style={{ textAlign: 'right' }}>
                {(e.shareOfVolumes * 100).toFixed(1)} %
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const featureCost = (featureId: string) => {
  return {
    'bat.h': 160,
    'bat.m': 75,
    'bat.l': 30,

    'mec.h': 135,
    'mec.m': 65,
    'mec.l': 20,

    'made-in.?': 0,
    'made-in.de': 0,
    'made-in.sp': 0,
    'made-in.pl': 0,
  }[featureId];
};
