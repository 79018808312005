import React from 'react';
import { percent, price } from '../layout/formatters';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { FeatureSelectionTable } from '../product/feature-selection-table';
import { VariableCostReport } from '../product/report.variable-cost';

export const KnowTheProduct = () => (
  <>
    <SectionTitle id="know-the-product">Conosci il Prodotto</SectionTitle>

    <p>
      <i>Inserire qui descrizione generale del prodotto...</i>
    </p>

    <Report>
      <h4 id="cost-per-unit-raw-material">Costo Unitario "Telaio" per Paese</h4>
      <span>
        <b>Costo Unitario di Riferimento Globale</b>: {price(5)}
      </span>
      <br />
      <br />
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr
            style={{
              verticalAlign: 'top',
              lineHeight: '1em',
            }}
          >
            <>
              <th style={{ width: '10%' }}>Paese</th>
              <th style={{ width: '10%', textAlign: 'right' }}>
                Indice di Costo per Paese
              </th>
            </>
          </tr>
        </thead>
        <tbody>
          {[
            { country: 'Germania', countryRatio: 0.98 },
            { country: 'Italia', countryRatio: 1.1 },
            { country: 'Francia', countryRatio: 1.05 },
            { country: 'Polonia', countryRatio: 1.03 },
          ].map((info, i) => (
            <tr key={i} style={{ background: i % 2 ? '#eee' : 'white' }}>
              <td>{info.country}</td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {percent(info.countryRatio)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="cost-per-unit-components">Costo Unitario Componenti</h4>
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '10%', textAlign: 'right' }}>Batteria L</th>
          </tr>
          <tr>
            <th style={{ width: '10%', textAlign: 'right' }}>Batteria M</th>
          </tr>
          <tr>
            <th style={{ width: '10%', textAlign: 'right' }}>Batteria H</th>
          </tr>
          <tr
            style={{
              verticalAlign: 'top',
              lineHeight: '1em',
            }}
          >
            <>
              <th style={{ width: '10%' }}>Paese</th>
            </>
          </tr>
        </thead>
        <tbody>
          {[
            {
              country: 'Germania',
              kitCost: 5,
              batteryLowCost: 5,
              batteryMidCost: 10,
              batteryHighCost: 15,
            },
            {
              country: 'Italia',
              kitCost: 5,
              batteryLowCost: 5,
              batteryMidCost: 10,
              batteryHighCost: 15,
            },
            {
              country: 'Francia',
              kitCost: 5,
              batteryLowCost: 5,
              batteryMidCost: 10,
              batteryHighCost: 15,
            },
            {
              country: 'Polonia',
              kitCost: 2,
              batteryLowCost: 5,
              batteryMidCost: 10,
              batteryHighCost: 15,
            },
          ].map((info, i) => (
            <tr key={i} style={{ background: i % 2 ? '#eee' : 'white' }}>
              <td>{info.country}</td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {price(info.kitCost)}
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {price(info.batteryLowCost)}
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {price(info.batteryMidCost)}
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {price(info.batteryHighCost)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="bill-of-materials">Distinta Base</h4>
      <i>Inserire qui distinta base del prodotto</i>
    </Report>
  </>
);
