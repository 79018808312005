import { percent, price } from '../layout/formatters';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';

const previousPeriod = {
  operations: {
    procurement: {
      rawMaterialFromLocalSupplier: {
        units: 10,
        unitPrice: 3.5, //TODEFINE: currency?
        paymentUpfront: 0.2,
      },
      rawMaterialFromOtherCompanies: {
        units: 10000,
        unitPrice: 3.5, //TODEFINE: currency?
        paymentUpfront: 0.2,
      },
    },
    production: {
      capacity: {
        standardTechnology: 10,
        evolvedTechnology: 20,
        total: 35000,
      },
      plan: {
        highEnd: 20,
        midEnd: 20,
        lowEnd: 20,
        total: 30000,
      },
      // questi di seguito vanno recuperati o calcolati?
      currentCapacity: 35000,
      setupInefficiency: 0.02,
      learningInefficiency: 0.05,
      totalCapacity: 32550,
      overCapacity: 2450,
    },
    outsourcing: {
      highEnd: {
        units: 2000,
        avgUnitPrice: 3.5,
        paymentUpfront: 20000,
      },
      midEnd: {
        units: 2000,
        avgUnitPrice: 3.5,
        paymentUpfront: 20000,
      },
      lowEnd: {
        units: 2000,
        avgUnitPrice: 3.5,
        paymentUpfront: 20000,
      },
    },
    warehouse: [
      {
        slotName: 'A',
        assetName: 'High End (Brand Alfa)',
        avgUnitValue: 300,
        startingInventoryUnits: 1000,
        deltaFromProductionUnits: 1000,
        deltaFromOutsourceUnits: 1000,
      },
      {
        slotName: 'B',
        assetName: 'Mid End (Brand Alfa)',
        avgUnitValue: 300,
        startingInventoryUnits: 1000,
        deltaFromProductionUnits: 1000,
        deltaFromOutsourceUnits: 1000,
      },
      {
        slotName: 'C',
        assetName: 'Low End (Brand Alfa)',
        avgUnitValue: 300,
        startingInventoryUnits: 1000,
        deltaFromProductionUnits: 1000,
        deltaFromOutsourceUnits: 1000,
      },
    ],
  },
  sales: {
    myBrand: [
      {
        brandName: 'Brand Name',
        status: 'on_the_market', // ENUM?
        type: 'owned',
        SKU: 'high',
        price: 200,
        nationalDistributionCoverage: 1500000,
        brandAwarenessInvestments: {
          IR: 1500000,
          IT: 1500000,
          EN: 1500000,
          FR: 1500000,
        },
        notes: 'capiamo',
      },
      {
        brandName: 'Brand Name 2',
        status: 'on_the_market', // ENUM?
        type: 'owned',
        SKU: 'high',
        price: 200,
        nationalDistributionCoverage: 1500000,
        brandAwarenessInvestments: {
          IR: 1500000,
          IT: 1500000,
          EN: 1500000,
          FR: 1500000,
        },
        notes: 'capiamo 2',
      },
    ],
    brandDistribution: [
      {
        brandName: 'Brand Name',
        status: 'on_the_market', // ENUM?
        type: 'distributed',
        counterpart: 'M. Osbourne export',
        SKU: 'high',
        price: 200,
        nationalDistributionCoverage: 1500000,
        brandAwarenessInvestments: {
          IR: 1500000,
        },
        contractDetails: {
          minUnits: 100,
          minBuyingPrice: 200,
          minSellingPrice: 200,
          minBrandInvestment: 200000,
        },
        notes: 'capiamo',
      },
      {
        brandName: 'Brand Name 2',
        status: 'on_the_market', // ENUM?
        type: 'distributed',
        counterpart: 'M. Osbourne export',
        SKU: 'high',
        price: 200,
        nationalDistributionCoverage: 1500000,
        brandAwarenessInvestments: {
          IR: 1500000,
        },
        contractDetails: {
          minUnits: 100,
          minBuyingPrice: 200,
          minSellingPrice: 200,
          minBrandInvestment: 200000,
        },
        notes: 'capiamo 2',
      },
    ],
  },
  financial: {
    loans: {
      scontoCrediti: 0.1,
      shortTermLoans: 0.1,
      midTermLoanValue: 100000,
    },
    bonds: {
      value: 10000,
    },
  },
};

export const NextQuarterDecisions = () => (
  <>
    <SectionTitle id="next-quarter-decisions">
      Decidi per il prossimo Periodo
    </SectionTitle>
    <p>
      Pianifica le tue azioni. Saranno elaborate non appena tutti i team avranno
      confermato le loro scelte.
    </p>

    <Interaction>
      <h4>Competing Offers</h4>
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th>brandName</th>
            <th>manufacturer</th>
            <th>distributor</th>
            <th>sku</th>
            <th>price</th>
            <th>market share</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CMP1.B1</td>
            <td>CMP1.B1</td>
            <td>CMP1.B1</td>
            <td>M.H-B.L</td>
            <td>{price(950)}</td>
            <td>--</td>
          </tr>
          <tr>
            <td>CMP1.B1</td>
            <td>CMP1.B1</td>
            <td>CMP1.B2</td>
            <td>M.H-B.L</td>
            <td>{price(1050)}</td>
            <td>--</td>
          </tr>
        </tbody>
      </table>

      <button>Evaluate Market Share</button>
      <button>Submit Offer</button>
    </Interaction>

    <Interaction>
      <h4>Procurement and Operations</h4>
      <h5>Procurement</h5>
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold', width: '50%' }}>
              Raw material from local supplier
            </td>
            <td>
              {
                previousPeriod.operations.procurement
                  .rawMaterialFromLocalSupplier.units
              }
            </td>
            <td>Units</td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>Unit price</td>
            <td>
              {
                previousPeriod.operations.procurement
                  .rawMaterialFromLocalSupplier.unitPrice
              }
            </td>
            <td>€/Units</td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>% of payment upfront</td>
            <td>
              {percent(
                previousPeriod.operations.procurement
                  .rawMaterialFromLocalSupplier.paymentUpfront
              )}
            </td>
            <td>%</td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '50%' }}>
              Raw material from other companies
            </td>
            <td>
              {
                previousPeriod.operations.procurement
                  .rawMaterialFromOtherCompanies.units
              }
            </td>
            <td>Units</td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>Unit price</td>
            <td>
              {
                previousPeriod.operations.procurement
                  .rawMaterialFromOtherCompanies.unitPrice
              }
            </td>
            <td>€/Units</td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>% of payment upfront</td>
            <td>
              {percent(
                previousPeriod.operations.procurement
                  .rawMaterialFromOtherCompanies.paymentUpfront
              )}
            </td>
            <td>%</td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '50%' }}>
              Total Incoming units
            </td>
            <td style={{ fontWeight: 'bold' }}>
              {previousPeriod.operations.procurement
                .rawMaterialFromLocalSupplier.units +
                previousPeriod.operations.procurement
                  .rawMaterialFromOtherCompanies.units}
            </td>
            <td style={{ fontWeight: 'bold' }}>Units</td>
          </tr>
        </tbody>
      </table>

      <h5>Production</h5>
      <h5>Outsourcing</h5>
      <h5>Warehouse</h5>

      <h4>Marketing and Sales</h4>
      <h5>My Brands</h5>

      <h5>Brand distribution</h5>

      <h4>Financial</h4>
      <h5>Debiti con Banche</h5>
      <h5>Titoli di Stato</h5>
    </Interaction>
  </>
);
