import produce from 'immer';
import { GetState, SetState } from 'zustand';
import { to, toSum, toUniq } from '../../../../lib/say-it';
import { IOperations } from './concepts';
export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>,
  get: GetState<E extends T ? E : E & T>
) => T;

export const create: StoreSlice<IOperations & IPrivate> = (set, get) => ({
  increaseCapacity(planId, volumes) {
    set(
      produce<IPrivate>(s => {
        add(s.capacities, planId, volumes);
      })
    );
  },

  increaseRawMaterialEfficiency(planId, factor) {
    set(
      produce<IPrivate>(s => {
        add(s.capacities, planId, factor);
      })
    );
  },

  plan(planId, sku, volumes) {
    set(
      produce<IPrivate>(s => {
        s.entries[`${planId}::${sku}`] = volumes;
      })
    );
  },

  unPlan(planId, sku) {
    set(
      produce<IPrivate>(s => {
        delete s.entries[`${planId}::${sku}`];
      })
    );
  },

  readPlannedVolumes(planId, sku) {
    return read(get().entries, `${planId}::${sku}`);
  },

  readTotalCapacity(planId) {
    return read(get().capacities, planId);
  },

  readRawMaterialEfficiency(planId) {
    return read(get().rawMaterialEfficiencies, planId);
  },

  readSetupInefficiency(planId) {
    return Math.max(0, get().countEntries(planId) - 1) * 0.02;
  },

  readLearningInefficiency(planId) {
    return (
      get().listPlannedSKUs(planId).filter(get().hasSKUBeenProduced).length *
      0.05
    );
  },

  readAvailableCapacity(planId) {
    const totalInefficiency =
      get().readSetupInefficiency(planId) +
      get().readLearningInefficiency(planId);

    return Math.round(
      get().readTotalCapacity(planId) * (1 - totalInefficiency)
    );
  },

  readNeededOverTime(planId) {
    return -Math.min(
      0,
      get().readAvailableCapacity(planId) -
        get().evaluateProductionVolumes(planId)
    );
  },

  isPlanDoable(planId) {
    return (
      get().readNeededOverTime(planId) < 0.1 * get().readTotalCapacity(planId)
    );
  },

  /** private */

  capacities: {
    '1': 350_000,
  },
  rawMaterialEfficiencies: {},
  entries: {
    '1::A': 15_000,
    '1::B': 45_000,
  },

  countEntries(planId) {
    return Object.keys(get().entries).filter(k => k.startsWith(planId)).length;
  },

  hasSKUBeenProduced(sku) {
    return true;
  },

  listPlannedSKUs(planId) {
    return Object.keys(get().entries)
      .filter(key => key.startsWith(planId))
      .map(key => key.split('::')[1])
      .reduce(toUniq(), []);
  },

  evaluateProductionVolumes(planId) {
    return Object.entries(get().entries)
      .filter(([key]) => key.startsWith(planId))
      .map(to('1'))
      .reduce(toSum(), 0);
  },
});

interface IPrivate {
  capacities: Record<string, number>;
  rawMaterialEfficiencies: Record<string, number>;
  entries: Record<string, number>;
}

export const add = (
  record: Record<string, number>,
  key: string,
  value: number
) => (record[key] = (record[key] || 0) + value);

export const mutate = (
  record: Record<string, number>,
  key: string,
  mutator: (v: number) => number
) => (record[key] = mutator(record[key] || 0));

export const read = (
  record: Record<string, number>,
  key: string,
  initValue: number = 0
) => record[key] || initValue;
