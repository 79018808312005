export const Report: React.FC<{
  children: React.ReactElement | React.ReactElement[];
}> = props => {
  return (
    <div
      style={{
        borderLeft: '1em solid blue',
        paddingLeft: '0.5em',
        marginTop: '1em',
      }}
    >
      {props.children}
    </div>
  );
};
