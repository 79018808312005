import { useStore } from '../../store';

export const StartButton = () => {
  const start = useStore(s => s.start);
  const isStarted = useStore(s => s.isStarted);

  if (isStarted) return null;

  return (
    <button
      onClick={() => {
        fetchGameState().then(s => {
          start(s.startTime);
        });
      }}
    >
      Inizia Partita
    </button>
  );
};

async function fetchGameState() {
  return { startTime: Date.now() };
}
