import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { StartButton } from './comps/game/start-button';
import { Initializer } from './comps/game/initializer';
import { IndexOfSections } from './index-of-sections';
import { IndexOfReports } from './index-of-reports';
import { useStore } from './store';
import { sections } from './sections';
import { Title } from '../lib/fanes-text';
import { Col, Container, Row } from '../lib/fanes-layout';
import { CelloMainLayout } from '../lib/cello';
import { Switch, Route } from 'wouter';
import { MarketSimulator } from './pages/market-simulator';
import { OperationsSimulator } from './pages/ops-simulator';

export const App = () => {
  return (
    <Switch>
      <Route path="/market-sim">
        <MarketSimulator />
      </Route>
      <Route path="/ops-sim">
        <OperationsSimulator />
      </Route>
      <Route
        path="*"
        component={() => (
          <Initializer>
            <CelloMainLayout summary={<Summary />} main={<Main />} />
          </Initializer>
        )}
      />
    </Switch>
  );
};

const Summary = () => {
  const runningTasks = useStore(s => s.runningTasks);

  return (
    <div className="sticky-top pt-4">
      <div className="mb-4">
        <IndexOfSections />
      </div>
      <hr />
      <div className="mb-4">
        <IndexOfReports />
      </div>
      <hr />
      Periodo 1 di 8<br />
      La partita non è ancora cominciata.
      <br />
      <StartButton />
    </div>
  );
};

const Main = () => (
  <Container>
    <Row>
      <Col size={9}>
        <Title text="Eurosim 2.0 - Prototype Alpha" />
      </Col>
    </Row>

    {sections.map((section, i) => (
      <Fragment key={i}>
        <Row>
          <Col size={9}>{section}</Col>
          <Col size={3}>
            <div style={{ paddingTop: '5em' }}>
              <span style={{ color: '#ccc', fontSize: '0.8em' }}>
                Room for teaching notes
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <div style={{ height: '10em' }}></div>
          </Col>
        </Row>
      </Fragment>
    ))}
  </Container>
);
