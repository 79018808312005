import { Param } from "../layout/param";
import { SectionTitle } from "../layout/section-title";
import { Story } from "../layout/story";

export const Background = () => {
  return (
    <>
      <SectionTitle id="background">Contesto</SectionTitle>

      <Story>
        <p>
          <i>Inserire qui alcune informazioni di contesto...</i>
        </p>
      </Story>
    </>
  );
};
