import React from "react";
import { percent } from "../layout/formatters";
import { Interaction } from "../layout/interaction";
import { Param } from "../layout/param";
import { Report } from "../layout/report";
import { SectionTitle } from "../layout/section-title";
import { FeatureSelectionTable } from "../product/feature-selection-table";
import { VariableCostReport } from "../product/report.variable-cost";
import { Term } from "../term";

export const KnowTheCompanies = () => (
  <>
    <SectionTitle id="know-the-companies">Conosci le Aziende</SectionTitle>

    <p>
      Nel mondo simulato sono presenti <Param>6</Param>{" "}
      <Term lemma="Aziende partecipanti" /> descritte nella tabella che segue.
    </p>

    <Report>
      <h4 id="active-companies">Tabella delle Aziende Partecipanti</h4>
      <i>Vanno distinte in giocanti e non giocanti?</i>
      {/* <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Paese</th>
              <th style={{ width: "10%" }}># Aziende</th>
              <th style={{ width: "10%", textAlign: "right" }}>
                Aliquota Tassazione
              </th>
            </>
          </tr>
        </thead>
        <tbody>
          {[
            { country: "Germania", numberOfFirms: 2, taxRate: 0.19 },
            { country: "Italia", numberOfFirms: 2, taxRate: 0.26 },
            { country: "Francia", numberOfFirms: 2, taxRate: 0.26 },
            { country: "Polonia", numberOfFirms: 1, taxRate: 0.12 },
          ].map((info, i) => (
            <tr key={i} style={{ background: i % 2 ? "#eee" : "white" }}>
              <td>{info.country}</td>
              <td style={{ fontSize: "0.8em", textAlign: "right" }}>
                {info.numberOfFirms}
              </td>
              <td style={{ fontSize: "0.8em", textAlign: "right" }}>
                {percent(info.taxRate)}
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </Report>
  </>
);
