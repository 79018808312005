import { useState } from 'react';
import { useOffers } from '../store';

export const PartialUtilityCoefficientSetup: React.FC<{
  countryId: string;
}> = props => {
  const features = useOffers(s => s.features);
  const segmentsIds = useOffers(s => s.segmentsIds);

  return (
    <table>
      <thead>
        <tr>
          <th rowSpan={2}>Features</th>
          <th colSpan={segmentsIds.length}>Segments</th>
        </tr>
        <tr>
          {segmentsIds.map((id, i) => (
            <th style={{ textAlign: 'center' }} key={i}>
              {id}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map((f, i) => (
          <tr key={i}>
            <td>{f}</td>
            {segmentsIds.map((s, j) => (
              <td key={j}>
                <PartialUtilityCoefficientInput
                  countryId={props.countryId}
                  featureId={f}
                  segmentId={s}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const PartialUtilityCoefficientInput: React.FC<{
  countryId: string;
  featureId: string;
  segmentId: string;
}> = props => {
  const coefficient = useOffers(s =>
    s.readUtilityCoefficient(props.countryId, props.featureId, props.segmentId)
  );
  const setUtilityCoefficient = useOffers(s => s.setUtilityCoefficient);

  const [v, setV] = useState(coefficient.toString());

  return (
    <input
      size={4}
      style={{ textAlign: 'right' }}
      value={v}
      onChange={e => setV(e.target.value)}
      onBlur={e =>
        setUtilityCoefficient(
          props.countryId,
          props.featureId,
          props.segmentId,
          Number(v)
        )
      }
    />
  );
};
