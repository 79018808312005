import { GameRules } from './comps/sections/game-rules';
import { KnowTheGeography } from './comps/sections/know-the-geography';
import { KnowTheCompanies } from './comps/sections/know-the-companies';
import { ManageShares } from './comps/sections/manage-shares';
import { ManageContracts } from './comps/sections/manage-contracts';
import { NextQuarterDecisions } from './comps/sections/decide';
import { KnowTheProduct } from './comps/sections/know-the-product';
import { KnowTheMarket } from './comps/sections/know-the-market';
import { AnalyzeResults } from './comps/sections/analyze-results';
import { Background } from './comps/sections/background';
import { KnowThePlant } from './comps/sections/know-the-plant';

export const sections = [
  <Background />,
  <GameRules />,
  <KnowTheGeography />,
  <KnowTheProduct />,
  <KnowThePlant />,
  <KnowTheMarket />,
  <KnowTheCompanies />,
  <ManageShares />,
  <ManageContracts />,
  <NextQuarterDecisions />,
];
