import produce from 'immer';
import { ICompanyBrands, ICompanyDecisions } from '../concepts/eurosim';
import { IGame } from '../concepts/game';
import { StoreSlice } from '../utils/store-slice';

interface IPrivate {
  ownedBrands: string[];
  distributedBrands: string[];
  skuByBrand: Record<string, string>;
  priceByBrand: Record<string, number>;
  distributionCoverageIndexByBrand: Record<string, number>;
  awarenessIndexByBrand: Record<string, number>;
}

export const create: StoreSlice<
  ICompanyBrands & IPrivate,
  IGame & ICompanyDecisions
> = (set, get) => {
  return {
    ownedBrands: [],
    distributedBrands: [],
    skuByBrand: {},
    priceByBrand: {},
    distributionCoverageIndexByBrand: {},
    awarenessIndexByBrand: {},

    listOwnedBrands() {
      return get().ownedBrands;
    },

    listDistributedBrands() {
      return get().distributedBrands;
    },

    brandSku(name) {
      return get().skuByBrand[name];
    },

    brandCurrentPrice(name: string): number {
      return get().priceByBrand[get().dimensionName(name)];
    },

    brandHistoricPrice(name: string, period: number): number {
      return get().priceByBrand[get().dimensionNameAt(name, period)];
    },

    brandDistributionCoverageIndex(name: string): number {
      return get().distributionCoverageIndexByBrand[get().dimensionName(name)];
    },

    brandAwarenessIndex(name: string): number {
      return get().awarenessIndexByBrand[get().dimensionName(name)];
    },

    launchBrand(name: string, sku: string, price: number): void {
      if (get().ownedBrands.includes(name)) {
        throw new Error(`Brand Name «${name}» has been launched already.`);
      }

      produce<IPrivate>(s => {
        s.skuByBrand[name] = sku;
        s.priceByBrand[get().dimensionName(name)] = price;
        s.ownedBrands.push(name);
      });
    },

    recordThirdPartyBrand(name, sku) {
      produce<IPrivate>(s => {
        s.skuByBrand[name] = sku;
      });
    },

    distributeBrand(name: string, price: number): void {
      if (get().distributedBrands.includes(name)) {
        throw new Error(`Brand Name «${name}» has been distributed already.`);
      }

      if (!get().skuByBrand[name]) {
        throw new Error(`Brand Name «${name}» has not been launched yet.`);
      }

      produce<IPrivate>(s => {
        s.priceByBrand[get().dimensionName(name)] = price;
        s.distributedBrands.push(name);
      });
    },

    setBrandPrice(name: string, price: number): void {
      if (
        !get().distributedBrands.includes(name) &&
        !get().ownedBrands.includes(name)
      ) {
        throw new Error(
          `Brand Name «${name}» has not been launched or distributed yet.`
        );
      }

      produce<IPrivate>(s => {
        s.priceByBrand[get().dimensionName(name)] = price;
      });
    },

    withdrawBrand(name: string): void {
      if (
        !get().distributedBrands.includes(name) &&
        !get().ownedBrands.includes(name)
      ) {
        throw new Error(
          `Brand Name «${name}» has not been launched or distributed yet.`
        );
      }

      produce<IPrivate>(s => {
        s.ownedBrands = s.ownedBrands.filter(b => b != name);
        s.distributedBrands = s.ownedBrands.filter(b => b != name);
      });
    },

    investInLocalBrandAwareness(name: string, amount: number): void {},

    investInInternationalBrandAwareness(name: string, amount: number): void {},
    investInDistributionCoverage(name: string, amount: number): void {},
    investInCountryBrandAwareness(
      name: string,
      amount: number,
      country: string
    ): void {},
  };
};
