export const IndexOfSections = () => (
  <>
    <h5>Index of Sections</h5>
    <ul>
      <li>
        <a href="#background">Contesto</a>
      </li>
      <li>
        <a href="#game-rules">Istruzioni</a>
      </li>
      <li>
        <a href="#know-the-geography">Conosci la Geografia</a>
      </li>
      <li>
        <a href="#know-the-product">Conosci il Prodotto</a>
      </li>
      <li>
        <a href="#know-the-market">Conosci il Mercato</a>
      </li>
      <li>
        <a href="#know-the-companies">Conosci le Aziende</a>
      </li>
      <li>
        <a href="#manage-shares">Gestisci le Partecipazioni</a>
      </li>
      <li>
        <a href="#manage-contracts">Gestisci i Contratti</a>
      </li>
      <li>
        <a href="#next-quarter-decisions">Decidi per il prossimo Periodo</a>
      </li>
      <li>
        <a href="#analyze-results">Analizza i Risultati</a>
      </li>
    </ul>
  </>
);
