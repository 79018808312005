import { Param } from '../layout/param';
import { SectionTitle } from '../layout/section-title';
import { Term } from '../term';

export const GameRules = () => {
  return (
    <>
      <SectionTitle id="game-rules">Istruzioni</SectionTitle>
      <h3>Tempo</h3>
      <p>
        <i>Eurosim 2.0</i> è una simulazione organizzata in{' '}
        <Term lemma="Periodi" /> in cui più squadre di partecipanti competono o
        collaborano per diventare la migliore azienda di «Droni» sulla piazza
        Europea.
      </p>
      <p>
        Ogni <Term lemma="Periodo" /> rappresenta <Param>un trimestre</Param>{' '}
        nel mondo simulato e dura circa <Param>60 minuti</Param> in tempo reale.
      </p>
      <p>
        <i>Inserire qui il resto delle istruzioni...</i>
      </p>
    </>
  );
};
