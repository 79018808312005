import { Col, Container, Row } from '../../fanes-layout';

export const CelloMainLayout: React.FC<{
  summary: React.ReactNode;
  main: React.ReactNode;
}> = props => (
  <Container>
    <Row>
      <Col size={3}>{props.summary}</Col>
      <Col size={9}>{props.main}</Col>
    </Row>
  </Container>
);
