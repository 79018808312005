export type Offer = {
  brand: string;
  features: string[];
  price: number;
  historicalPrice: number;
  brandAwarenessInvestment: number;
};

export const ifOfSameValueDriver = (featureId: string) =>
  ifValueDriverIs(valueDriverOf(featureId));

export const ifValueDriverIs = (valueDriver: string) => (feature: string) =>
  feature.split('.')[0] === valueDriver;

export const valueDriverOf = (featureId: string) => {
  const [valueDriver] = featureId.split('.');
  return valueDriver;
};

export const brandAwarenessIndex =
  () => (offer: { brandAwarenessInvestment: number }) => {
    const v = offer.brandAwarenessInvestment / 1000;
    if (v < 2.3) return 1 / (0.02 + Math.exp(-v + 3));
    return 1 / (1 + Math.exp(-v + 2.3));
  };

export * from './evaluate-market-preference';
