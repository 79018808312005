import React from "react";
import { ContractsReport } from "../../store/concepts/eurosim";
import {
  AdditionalChargesInput,
  BondNameSelect,
  BuyerCompanySelect,
  BuyerSelect,
  BuyingPriceInput,
  CapitalAmountInput,
  ContractForm,
  DistributedBrandNameSelect,
  DistributionCompanySelect,
  InstalmentsInput,
  InterestRateInput,
  IssuingCompanySelect,
  MinBrandAwarenessInvestmentInput,
  MinPurchaseVolumeInput,
  MinSellingPriceInput,
  ProductionCompanySelect,
  RepaymentPeriodsInput,
  RequestingCompanySelect,
  SellerSelect,
  SKUSelect,
  UnitPriceInput,
  UnitsInput,
  UpfrontPaymentInput,
  ValueInput,
} from "../forms/contracts";
import { percent, price } from "../layout/formatters";
import { Interaction } from "../layout/interaction";
import { Report } from "../layout/report";
import { SectionTitle } from "../layout/section-title";

const report: ContractsReport = {
  procurement: [
    {
      action: "buy",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: -200000,
      initialQuarter: "III",
      finalQuarter: "III",
      status: "active",
    },
    {
      action: "buy",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: -200000,
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "active",
    },
    {
      action: "sell",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: -200000,
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "stipulated",
    },
    {
      action: "sell",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: -200000,
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "expired",
    },
  ],
  production: [
    {
      action: "buy",
      SKU: "High End",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: "-200K",
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "stipulated",
    },
    {
      action: "sell",
      SKU: "Mid End",
      counterpart: "Company Name",
      units: 200,
      pricePerUnit: 200,
      paymentUpfront: 0.2,
      total: "-200K",
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "stipulated",
    },
  ],
  marketing: [
    {
      brandName: "Brand Name",
      counterpart: "Company Name",
      minUnits: 200,
      minBuyingPrice: 200,
      minSellingPrice: 200,
      minBrandInvestment: "200K",
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "active",
    },
    {
      brandName: "Brand Name",
      counterpart: "Company Name",
      minUnits: 200,
      minBuyingPrice: 200,
      minSellingPrice: 200,
      minBrandInvestment: "200K",
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "active",
    },
    {
      brandName: "Brand Name",
      counterpart: "Company Name",
      minUnits: 200,
      minBuyingPrice: 200,
      minSellingPrice: 200,
      minBrandInvestment: "200K",
      initialQuarter: "III",
      finalQuarter: "IV",
      status: "expired",
    },
  ],
  loan: [
    {
      action: "Medium loan for",
      company: "Your Company",
      value: "20%/capital 200K/€",
      initialQuarter: "III",
      finalQuarter: "IV",
      tranche: "20K€/period",
      status: "active",
    },
    {
      action: "Medium loan for",
      company: "Your Company",
      value: "20%/capital 200K/€",
      initialQuarter: "III",
      finalQuarter: "IV",
      tranche: "20K€/period",
      status: "active",
    },
    {
      action: "Medium loan for",
      company: "Your Company",
      value: "20%/capital 200K/€",
      initialQuarter: "III",
      finalQuarter: "IV",
      tranche: "20K€/period",
      status: "active",
    },
  ],
  issueBond: [
    {
      buyer: "Company Name",
      bondName: "Bond Name",
      value: 350,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "active",
    },
    {
      buyer: "Company Name 2",
      bondName: "Bond Name 2",
      value: 350,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "active",
    },
    {
      buyer: "Company Name 3",
      bondName: "Bond Name 3",
      value: 350,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "active",
    },
  ],
  buyBond: [
    {
      issuingCompany: "Company Name",
      bondName: "Bond Name",
      value: 123,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "active",
    },
    {
      issuingCompany: "Company Name 2",
      bondName: "Bond Name 2",
      value: 123,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "active",
    },
    {
      issuingCompany: "Company Name 3",
      bondName: "Bond Name 3",
      value: 123,
      initialQuarter: "III",
      finalQuarter: "IV",
      interestRate: 0.07,
      status: "stipulated",
    },
  ],
};

export const ManageContracts = () => (
  <>
    <SectionTitle id="manage-contracts">Gestisci i Contratti</SectionTitle>

    <Report>
      <h4 id="procurement-contracts">
        Contratti di Acquisto/Vendita Materia Prima
      </h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Action</th>
              <th style={{ width: "15%" }}>Counterpart</th>
              <th style={{ width: "10%" }}>Units</th>
              <th style={{ width: "10%" }}>price per unit</th>
              <th style={{ width: "8%" }}>payment upfront</th>
              <th style={{ width: "10%", fontWeight: "bold" }}>Total</th>
              <th style={{ width: "10%", fontWeight: "bold" }}>Period</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.procurement.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>{info.action}</td>
              <td>
                {info.action == "buy" ? "from " : "to "}
                {info.counterpart}
              </td>
              <td>{info.units} units</td>
              <td>{info.pricePerUnit}€/unit</td>
              <td>{percent(info.paymentUpfront)}</td>
              <td>{info.total}€</td>
              <td>
                {info.initialQuarter}
                {info.finalQuarter == info.initialQuarter
                  ? ""
                  : `-${info.finalQuarter}`}
              </td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="production-contracts">Contratti di Acquisto/Vendita SKU</h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Action</th>
              <th style={{ width: "13%" }}></th>
              <th style={{ width: "13%" }}>Counterpart</th>
              <th style={{ width: "10%" }}>Units</th>
              <th style={{ width: "13%" }}>price per unit</th>
              <th style={{ width: "10%" }}>payment upfront</th>
              <th style={{ width: "10%", fontWeight: "bold" }}>Total</th>
              <th style={{ width: "10%", fontWeight: "bold" }}>Period</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.production.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>{info.action}</td>
              <td style={{ fontWeight: "bold" }}>{info.SKU}</td>
              <td>
                {info.action == "Buy" ? "from " : "to "}
                {info.counterpart}
              </td>
              <td>{info.units} units</td>
              <td>{info.pricePerUnit}€/unit</td>
              <td>{percent(info.paymentUpfront)}</td>
              <td>{info.total}€</td>
              <td>
                {info.initialQuarter}
                {info.finalQuarter == info.initialQuarter
                  ? ""
                  : `-${info.finalQuarter}`}
              </td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="marketing-contracts">Contratti di Distribuzione</h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "13%" }}>Action</th>
              <th style={{ width: "10%" }}>Brand name</th>
              <th style={{ width: "13%" }}>Counterpart</th>
              <th style={{ width: "10%" }}>minim units</th>
              <th style={{ width: "13%" }}>min. buying price</th>
              <th style={{ width: "10%" }}>min. selling price</th>
              <th style={{ width: "13%" }}>min. brand investment</th>
              <th style={{ width: "7%", fontWeight: "bold" }}>Period</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.marketing.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>Distribution of</td>
              <td style={{ fontWeight: "bold" }}>{info.brandName}</td>
              <td>
                {/* TODO: usare ENUMS? */}
                {`for ${info.counterpart}`}
              </td>
              <td>{info.minUnits} units</td>
              <td>{info.minBuyingPrice}€/unit</td>
              <td>{info.minSellingPrice}€/unit</td>
              <td>{info.minBrandInvestment}€/period</td>
              <td>
                {info.initialQuarter}
                {info.finalQuarter == info.initialQuarter
                  ? ""
                  : `-${info.finalQuarter}`}
              </td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="loan-contracts">Contratti di Prestito a Medio Termine</h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Action</th>
              <th style={{ width: "13%" }}>Company</th>
              <th style={{ width: "10%" }}>value</th>
              <th style={{ width: "13%" }}>period</th>
              <th style={{ width: "10%" }}>tranche</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.loan.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>{info.action}</td>
              <td>{info.company}</td>
              <td>{info.value}</td>
              <td>
                {info.initialQuarter} - {info.finalQuarter}
              </td>
              <td>{info.tranche}</td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="issue-bond-contracts">Contratti di Emissione Obbligazioni</h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Buyer</th>
              <th style={{ width: "13%" }}>Bond Name</th>
              <th style={{ width: "10%" }}>Value</th>
              <th style={{ width: "13%" }}>Repayment periods</th>
              <th style={{ width: "10%" }}>Interest rate</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.issueBond.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>{info.buyer}</td>
              <td>{info.bondName}</td>
              <td>{info.value}€</td>
              <td>
                {info.initialQuarter} - {info.finalQuarter}
              </td>
              <td>{percent(info.interestRate)}</td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Report>
      <h4 id="buy-bond-contracts">Contratti di Sottoscrizione Obbligazioni</h4>
      <table style={{ tableLayout: "fixed", width: "100%" }}>
        <thead>
          <tr
            style={{
              verticalAlign: "top",
              lineHeight: "1em",
            }}
          >
            <>
              <th style={{ width: "10%" }}>Issuing Company</th>
              <th style={{ width: "13%" }}>Bond Name</th>
              <th style={{ width: "10%" }}>Value</th>
              <th style={{ width: "13%" }}>Repayment periods</th>
              <th style={{ width: "10%" }}>Interest rate</th>
              <th style={{ width: "10%" }}></th>
            </>
          </tr>
        </thead>
        <tbody>
          {report.buyBond.map((info, i) => (
            <tr
              key={i}
              style={{
                background: i % 2 ? "#eee" : "white",
                fontSize: "0.8em",
              }}
            >
              <td>{info.issuingCompany}</td>
              <td>{info.bondName}</td>
              <td>{info.value}€</td>
              <td>
                {info.initialQuarter} - {info.finalQuarter}
              </td>
              <td>{percent(info.interestRate)}</td>
              <td style={{ textAlign: "right" }}>{info.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Report>

    <Interaction>
      <h4>Acquista/Vendi Materia Prima</h4>
      <ContractForm>
        <BuyerSelect />
        <SellerSelect />
        <UnitsInput />
        <UnitPriceInput />
        <div>Total {"50K"}</div>
        <UpfrontPaymentInput />
      </ContractForm>
    </Interaction>

    <Interaction>
      <h4>Acquista/Vendi SKU</h4>
      <ContractForm>
        <BuyerSelect />
        <SellerSelect />
        <SKUSelect />
        <UnitsInput />
        <UnitPriceInput />
        <div>Total {"50K"}</div>
        <UpfrontPaymentInput />
      </ContractForm>
    </Interaction>

    <Interaction>
      <h4>Distribuisci Brand</h4>
      <ContractForm>
        <ProductionCompanySelect />
        <DistributionCompanySelect />
        <DistributedBrandNameSelect />
        <MinPurchaseVolumeInput />
        <BuyingPriceInput />
        <MinSellingPriceInput />
        <MinBrandAwarenessInvestmentInput />
        <UpfrontPaymentInput />
      </ContractForm>
    </Interaction>

    <Interaction>
      <h4>Richiedi Prestito a Medio Termine</h4>
      <ContractForm>
        <RequestingCompanySelect />
        <CapitalAmountInput />
        <InterestRateInput />
        <InstalmentsInput />
        <AdditionalChargesInput />
      </ContractForm>
    </Interaction>

    <Interaction>
      <h4>Emetti Obbligazioni</h4>
      <ContractForm>
        <IssuingCompanySelect />
        <BondNameSelect />
        <ValueInput />
        <RepaymentPeriodsInput />
        <InterestRateInput />
      </ContractForm>
    </Interaction>

    <Interaction>
      <h4>Sottoscrivi obbligazioni</h4>
      <ContractForm>
        <BuyerCompanySelect />
        <BondNameSelect />
        <ValueInput />
      </ContractForm>
    </Interaction>
  </>
);
