import { IComponentFactoryFn, IComponents } from '../concepts/components';
import { StoreSliceWithPrivate } from '../utils/store-slice';
import produce from 'immer';
import React from 'react';

interface IPrivate {
  factories: Record<string, IComponentFactoryFn<any>>;
  components: Record<string, React.FC>;
}

export const create: StoreSliceWithPrivate<IComponents, IPrivate> = (
  set,
  get
) => ({
  factories: {},
  components: {},

  assign(token, factory) {
    set(
      produce<IPrivate>(s => {
        s.factories[token] = factory;
      })
    );
  },

  use(token) {
    if (get().components[token]) return get().components[token];
    const factory = get().factories[token];

    if (!factory)
      return () =>
        React.createElement('span', {
          children: `FANES says: "no factory assigned to token «${token}»"`,
        });

    const component = factory(get());
    set(
      produce<IPrivate>(s => {
        s.components[token] = component;
      })
    );

    return component;
  },
});
