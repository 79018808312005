import { Fragment, useState } from 'react';
import { ifOfSameValueDriver, valueDriverOf } from '../model';
import { useOffers } from '../store';

export const PartialUtilitySetup: React.FC = () => {
  const features = useOffers(s => s.features);
  const segmentsIds = useOffers(s => s.segmentsIds);

  const isFirstOfItKind = useIsFirstOfItValueDriver(features);
  const countOfSameKind = useCountFeaturesOfSameValueDriver(features);

  return (
    <table style={{ width: 500 }}>
      <thead>
        <tr>
          <th rowSpan={3}>Features</th>
          <th style={{ textAlign: 'center' }} colSpan={segmentsIds.length * 2}>
            Segments
          </th>
        </tr>
        <tr>
          {segmentsIds.map((id, i) => (
            <th style={{ textAlign: 'center' }} key={i} colSpan={2}>
              {id}
            </th>
          ))}
        </tr>
        <tr>
          {segmentsIds.map((id, i) => (
            <Fragment key={i}>
              <th style={{ textAlign: 'center' }}>imp.</th>
              <th style={{ textAlign: 'center' }}>p.u.</th>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map((f, i) => (
          <tr key={i}>
            <td>{f}</td>
            {segmentsIds.map((s, j) => (
              <Fragment key={j}>
                {isFirstOfItKind(f) && (
                  <td rowSpan={countOfSameKind(f)}>
                    {
                      <ValueDriverImportance
                        valueDriverId={valueDriverOf(f)}
                        segmentId={s}
                      />
                    }
                  </td>
                )}
                <td>
                  <PartialUtilityInput featureId={f} segmentId={s} />
                </td>
              </Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const PartialUtilityInput: React.FC<{
  featureId: string;
  segmentId: string;
}> = props => {
  const utility = useOffers(s =>
    s.featurePartialUtility(props.featureId, props.segmentId)
  );
  const setBasePartialUtility = useOffers(s => s.setBasePartialUtility);

  const [v, setV] = useState(utility.toString());

  return (
    <input
      size={4}
      style={{ textAlign: 'right' }}
      value={v}
      onChange={e => setV(e.target.value)}
      onBlur={e =>
        setBasePartialUtility(props.featureId, props.segmentId, Number(v))
      }
    />
  );
};

const ValueDriverImportance: React.FC<{
  valueDriverId: string;
  segmentId: string;
}> = props => {
  const importance = useOffers(s =>
    s.valueDriverImportance(props.valueDriverId, props.segmentId)
  );

  return <span>{(importance * 100).toFixed(1)} %</span>;
};

const useCountFeaturesOfSameValueDriver =
  (featureIds: string[]) => (featureId: string) => {
    return featureIds.filter(ifOfSameValueDriver(featureId)).length;
  };

const useIsFirstOfItValueDriver =
  (featureIds: string[]) => (featureId: string) => {
    return (
      featureIds.indexOf(featureId) ===
      featureIds.findIndex(ifOfSameValueDriver(featureId))
    );
  };
