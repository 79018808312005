import { CountryMarketTable } from './comps/country-market-table';
import { LaunchBrand } from './comps/launch-brand';
import { MultiPeriodChart } from './comps/multi-period-graph';
import { PartialUtilitySetup } from './comps/partial-utilities-setup';

export const MarketSimulator = () => {
  return (
    <>
      <h1>Eurosim 2.0 - Market Simulator</h1>
      <h2>Partial Utility Global Reference Setup</h2>
      <PartialUtilitySetup />
      <hr />
      <h2>Country Markets</h2>
      <CountryBlock country="spain" />
      <CountryBlock country="italy" />
      <CountryBlock country="poland" />
      <CountryBlock country="germany" />
      <hr />
      <h2>Launch Product</h2>
      <LaunchBrand />
    </>
  );
};

const CountryBlock: React.FC<{ country: string }> = props => (
  <>
    <CountryTitle country={props.country} />
    <CountryMarketTable country={props.country} />
    <MultiPeriodChart country={props.country} />
  </>
);

const CountryTitle: React.FC<{ country: string }> = props => (
  <h3 style={{ fontWeight: 'bold', backgroundColor: 'pink' }}>
    Country: {props.country}
  </h3>
);
