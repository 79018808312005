import create, { GetState, SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import * as Game from './factories/game';
import * as Logger from './factories/logger';
import * as Player from './factories/player';
import * as CompanyBrands from './factories/company-brands';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Game.create(set, get),
  ...Logger.create(set, get),
  ...Player.create(set, get),
  ...CompanyBrands.create(set, get),
});

export const useStore = create(
  persist(createRootSlice, {
    name: 'high-voltage',
  })
);
