const BlockLabel = ({ children, style }) => (
  <label style={{ ...style, display: "block", margin: "5px 0" }}>
    {children}
  </label>
);

export const ContractForm = ({ children }) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
    }}
  >
    {children}
    <BlockLabel
      style={{
        "border-top": "1px solid #dfdfdf",
        "margin-top": "10px",
        "padding-top": "10px",
      }}
    >
      CODE
      <input value="10203040" />
    </BlockLabel>
    <input type="submit" value="CONFIRM" />
    <p>
      <i>
        chiedi alla controparte di generare un codice nella sua area contratti
      </i>
    </p>
  </form>
);

export const BuyerSelect = () => (
  <BlockLabel>
    Buyer
    <select name="buyer">
      <option id="one">My Company</option>
      <option id="two">Shelby lmtd</option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);

export const SellerSelect = () => (
  <BlockLabel>
    Seller
    <select name="buyer">
      <option id="one">My Company</option>
      <option id="two" selected>
        Shelby lmtd
      </option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);

export const SKUSelect = () => (
  <BlockLabel>
    SKU Code
    <select name="sku">
      <option id="high">High</option>
      <option id="mid" selected>
        Mid
      </option>
      <option id="low">Low</option>
    </select>
  </BlockLabel>
);

export const UnitsInput = () => (
  <BlockLabel>
    Units
    <input type="number" />
  </BlockLabel>
);

export const UnitPriceInput = () => (
  <BlockLabel>
    Unit price
    <input type="number" />
    €/unit
  </BlockLabel>
);

export const UpfrontPaymentInput = () => (
  <BlockLabel>
    Upfront payment
    <input type="number" /> %
  </BlockLabel>
);

export const ProductionCompanySelect = () => (
  <BlockLabel>
    Production company
    <select name="buyer">
      <option id="one">My Company</option>
      <option id="two">Shelby lmtd</option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);

export const DistributionCompanySelect = () => (
  <BlockLabel>
    Distribution concept
    <select name="buyer">
      <option id="one">My Company</option>
      <option id="two" selected>
        Shelby lmtd
      </option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);

export const DistributedBrandNameSelect = () => (
  <BlockLabel>
    Distributed Brand name
    <select name="brand">
      <option id="one">Brand One</option>
      <option id="two">Brand Two</option>
      <option id="three">Brand Three</option>
    </select>
  </BlockLabel>
);

export const MinPurchaseVolumeInput = () => (
  <BlockLabel>
    Guaranteed minimum volume of purchase
    <input type="number" /> units
  </BlockLabel>
);

export const BuyingPriceInput = () => (
  <BlockLabel>
    Buying price
    <input type="number" /> €/unit
  </BlockLabel>
);

export const MinSellingPriceInput = () => (
  <BlockLabel>
    Minimum selling price
    <input type="number" /> €/unit
  </BlockLabel>
);

export const MinBrandAwarenessInvestmentInput = () => (
  <BlockLabel>
    Guaranteed minimum brand awareness investment
    <input type="number" /> €/period
  </BlockLabel>
);

export const RequestingCompanySelect = () => (
  <BlockLabel>
    Company
    <select name="company">
      <option id="one">My Company</option>
      <option id="two" selected>
        Shelby lmtd
      </option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);
export const CapitalAmountInput = () => (
  <BlockLabel>
    Capital amount
    <input type="number" />€
  </BlockLabel>
);
export const InterestRateInput = () => (
  <BlockLabel>
    Interest rate
    <input type="number" /> %
  </BlockLabel>
);
export const InstalmentsInput = () => (
  <BlockLabel>
    Instalments
    <input type="number" /> periods
  </BlockLabel>
);
export const AdditionalChargesInput = () => (
  <BlockLabel>
    Additional charges
    <input type="number" />€
  </BlockLabel>
);

export const IssuingCompanySelect = () => (
  <BlockLabel>
    Issuing company
    <select name="company">
      <option id="one">My Company</option>
      <option id="two" selected>
        Shelby lmtd
      </option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);
export const BuyerCompanySelect = () => (
  <BlockLabel>
    Buyer
    <select name="company">
      <option id="one">My Company</option>
      <option id="two" selected>
        Shelby lmtd
      </option>
      <option id="three">Cocao Colao</option>
    </select>
  </BlockLabel>
);
export const BondNameSelect = () => (
  <BlockLabel>
    Bond name
    <select name="bond">
      <option id="one">Bond One</option>
      <option id="two">Bond Two</option>
      <option id="three">Bond Three</option>
    </select>
  </BlockLabel>
);
export const ValueInput = () => (
  <BlockLabel>
    Value
    <input type="number" />€
  </BlockLabel>
);
export const RepaymentPeriodsInput = () => (
  <BlockLabel>
    Repayment
    <input type="number" /> periods
  </BlockLabel>
);
