import { IGame, PlayOptions } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IPlayer } from '../concepts/player';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  setDoc,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyD5uIACipLEc7ov_x94Ceih2hocEcFosgg',
  authDomain: 'high-voltage-c338e.firebaseapp.com',
  projectId: 'high-voltage-c338e',
  storageBucket: 'high-voltage-c338e.appspot.com',
  messagingSenderId: '926285356853',
  appId: '1:926285356853:web:4f2a02fbf98abb465a767c',
};

const fb = initializeApp(firebaseConfig);
const db = getFirestore(fb);

export const create: StoreSlice<IGame & IPrivate, ILogger & IPlayer> = (
  set,
  get
) => {
  return {
    isInitialized: false,
    isStarted: false,
    isStopped: false,
    isDebugModeOn: false,

    totalWindowsOfOpportunities: 8,
    sprintDurationInSeconds: 0,
    challengeDurationInSeconds: 0,

    currentChallenge: 1,
    currentSprint: 0,
    currentSprintStartTime: 0,
    currentSprintEndTime: 0,

    _sprintDurationInMillis: 0,
    _challengeDurationInMillis: 0,
    _windowOfOpportunityDurationInMillis: 0,

    _unsubscribe: () => {},

    init(opts) {
      set({
        gameId: opts.gameId,
        playerId: opts.playerId,
        totalWindowsOfOpportunities: opts.totalWindowsOfOpportunities,
        _playOptions: opts,
        isInitialized: true,
        sprintDurationInSeconds: opts.sprintDurationInSeconds,
        challengeDurationInSeconds: opts.challengeDurationInSeconds,
        _challengeDurationInMillis: opts.challengeDurationInSeconds * 1000,
        _sprintDurationInMillis: opts.sprintDurationInSeconds * 1000,
        _windowOfOpportunityDurationInMillis:
          (opts.challengeDurationInSeconds + opts.sprintDurationInSeconds) *
          1000,
      });
    },

    windowOfOpportunityDurationInSeconds() {
      return get()._windowOfOpportunityDurationInMillis / 1000;
    },

    start(time) {
      if (get().isStarted) return;

      const ref = doc(db, `games`, get().gameId);

      setDoc(ref, {
        isStarted: true,
        startTime: time,
      })
        .then(() => {
          set({
            _startTime: time,
            isStarted: true,
          });

          get().log(`game started`);
          get()._initiateSprint(0);
          get()._run();
        })
        .catch(err => console.error(err));
    },

    _run: () => {
      if (!get().isStarted) return;
      // if (get().isStopped) return;

      clearInterval(get()._interval);
      set({
        _interval: setInterval(
          get()._onTick,
          get()._tickDuration()
        ) as unknown as number,
      });

      get().log(`game run, tick duration: ${get()._tickDuration()}`);
    },

    stop: () => {
      get().log('game stopped');
      get()._unsubscribe();
      clearInterval(get()._interval);
      set({ _interval: 0, isStopped: true, _stopTime: Date.now() });
    },

    timeElapsed: (now: number = Date.now()) => {
      if (!get().isStarted) return 0;
      if (get().isStopped) return get()._stopTime - get()._startTime;
      return now - get()._startTime;
    },

    sprintProgress: (now: number = Date.now()) => {
      return (
        (now - get().currentSprintStartTime) / get()._sprintDurationInMillis
      );
    },

    _playOptions: {
      gameId: '--null--',
      playerId: '--null',
      sprintDurationInSeconds: 300,
      challengeDurationInSeconds: 30,
      totalWindowsOfOpportunities: 8,
    },

    _startTime: 0,
    _stopTime: 0,
    _interval: 0,

    _tickDuration: () => {
      return 1000;

      // return (
      //   tickDuration(
      //     get()._playOptions.challengeDurationInSeconds,
      //     get()._playOptions.sprintDurationInSeconds
      //   ) * 1000
      // );
    },

    _timeFromStart: () => {
      return Date.now() - get()._startTime;
    },

    _onTick: () => {},

    _initiateSprint: (sprint: number) => {},
  };
};

interface IPrivate {
  _playOptions: PlayOptions;
  _startTime: number;
  _stopTime: number;
  _interval: number;
  _sprintDurationInMillis: number;
  _challengeDurationInMillis: number;
  _windowOfOpportunityDurationInMillis: number;
  _tickDuration(): number;
  _timeFromStart(): number;
  _initiateSprint(sprint: number): void;
  _run(): void;
  _onTick(): void;
  _unsubscribe: Unsubscribe;
}

type TimeInfo = {
  startTime: number;
  now: number;
  challengeDuration: number;
  breakDuration: number;
};

export const serialize = (s: DeepPartial<IGame & IPrivate>) => s;

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<IGame & IPrivate> => s;
