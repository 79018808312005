import { Interaction } from "../layout/interaction";
import { Report } from "../layout/report";
import { SectionTitle } from "../layout/section-title";

export const ManageShares = () => (
  <>
    <SectionTitle id="manage-shares">Gestisci le Partecipazioni</SectionTitle>

    <Report>
      <h4 id="participating-companies">Consultazione Libro Soci</h4>
      <i>Inserire qui tabella soci</i>
    </Report>

    <Interaction>
      <h4>Vendi partecipazione di una Partecipata</h4>
    </Interaction>

    <Interaction>
      <h4>Distribuisci Dividendi</h4>
    </Interaction>

    <Interaction>
      <h4>Compra partecipazione di una Partecipata</h4>
    </Interaction>
  </>
);
