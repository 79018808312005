export const IndexOfReports = () => (
  <>
    <h5>Index of Reports</h5>
    <ul>
      <li>
        <a href="#active-countries">Tabella dei Paesi Attivi</a>
      </li>
      <li>
        <a href="#cost-per-unit-raw-material">Costo Materia Prima per Paese</a>
      </li>
      <li>
        <a href="#cost-per-unit-components">Costo Componenti per Paese</a>
      </li>
      <li>
        <a href="#bill-of-materials">Distinta Base</a>
      </li>
      <li>
        <a href="#market-infos">Informazioni sul Mercato</a>
      </li>
      <li>
        <a href="#active-companies">Tabella delle Aziende partecipanti</a>
      </li>
      <li>
        <a href="#participating-companies">Libro Soci</a>
      </li>
      <li>
        <a href="#procurement-contracts">
          Contratti di Acquisto/Vendita Materia Prima
        </a>
      </li>
      <li>
        <a href="#production-contracts">Contratti di Acquisto/Vendita SKU</a>
      </li>
      <li>
        <a href="#marketing-contracts">Contratti di Distribuzione</a>
      </li>
      <li>
        <a href="#loan-contracts">Contratti di Prestito a Medio Termine</a>
      </li>
      <li>
        <a href="#issue-bond-contracts">Contratti di Emissione Obbligazioni</a>
      </li>
      <li>
        <a href="#buy-bond-contracts">
          Contratti di Sottoscrizione Obbligazioni
        </a>
      </li>
      <li>
        <a href="#previous-quarter">Riepilogo Periodo precedente</a>
      </li>
      <li>
        <a href="#economy-of-learning">Novità sulla Concorrenza</a>
      </li>
      <li>
        <a href="#production-capacity">Risultati Economici per SKU</a>
      </li>
      <li>
        <a href="#production-capacity">Risultati Economici per Brand Name</a>
      </li>
      <li>
        <a href="#competitors-tracking">Conto Economico</a>
      </li>
      <li>
        <a href="#demand-and-preference">Stato Patrimoniale</a>
      </li>
      <li>
        <a href="#production-volume-summary">Flussi di Cassa</a>
      </li>
      <li>
        <a href="#income-statement">Stato Finanziamenti</a>
      </li>
      <li>
        <a href="#income-statement">Stato Magazzino</a>
      </li>
    </ul>
  </>
);
