import React from 'react';
import { makeComponent } from '../../fanes';

export type ColumnProps = {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  children?: React.ReactNode | React.ReactNode[];
};

export const ColumnToken = 'layout.column';

export const Col = makeComponent<ColumnProps>(ColumnToken);
