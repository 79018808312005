import produce from 'immer';
import create, { GetState, SetState } from 'zustand';
import {
  ifValueOf,
  isEqualTo,
  to,
  toGroupsBy,
  toStruct,
  toSum,
} from '../../../../lib/say-it';
import {
  brandAwarenessIndex,
  evaluateMarketPreference,
  Offer,
  OfferEvaluation,
  OfferToEvaluate,
  valueDriverOf,
} from '../model';

interface IOffers {
  features: string[];

  /** segments preferences */
  basePreferences: Record<string, number>;
  setBasePartialUtility(
    featureId: string,
    segmentId: string,
    value: number
  ): void;
  featurePartialUtility(featureId: string, segmentId: string): number;
  valueDriverImportance(valueDriverId: string, segmentId: string): number;

  utilityCoefficientsByCountry: Record<string, number>;
  readUtilityCoefficient(
    countryId: string,
    featureId: string,
    segmentId: string
  ): number;
  setUtilityCoefficient(
    countryId: string,
    featureId: string,
    segmentId: string,
    value: number
  ): void;
  featurePartialUtilityInCountry(
    countryId: string
  ): (featureId: string, segmentId: string) => number;

  /** segment sizes */
  segmentsIds: string[];
  segments: Array<{ id: string; size: number; country: string }>;
  segmentSizeByCountry(
    country: string
  ): Array<{ id: string; size: number; relativeSize: number }>;
  incrementSegmentSize(countryId: string, segmentId: string): void;
  decrementSegmentSize(countryId: string, segmentId: string): void;

  /** offers */
  offers: Offer[];
  offersToEvaluate(): OfferToEvaluate[];
  launchBrand(offer: Offer): void;
  withdrawBrand(name: string): void;

  /** evaluations */
  offerEvaluationsByPeriod: Record<string, OfferEvaluation[]>;
  findOfferEvaluations(
    country: string
  ): (
    period: string
  ) => Array<OfferEvaluation & { period: string; country: string }>;
  evaluateOffers(
    period: string,
    market: string,
    offers: OfferToEvaluate[]
  ): void;
}

const offersStoreFactory: StoreSlice<IOffers> = (set, get) => {
  return {
    features: [
      'bat.h',
      'bat.m',
      'bat.l',
      'mec.h',
      'mec.m',
      'mec.l',
      'made-in.?',
      'made-in.de',
      'made-in.sp',
      'made-in.pl',
    ],

    basePreferences: {
      'hig-bat.h': 4.1,
      'hig-bat.m': -1.5,
      'hig-bat.l': -4.9,

      'hig-mec.h': 4.5,
      'hig-mec.m': -2.5,
      'hig-mec.l': -5,

      'hig-made-in.?': 0,
      'hig-made-in.de': 0.5,
      'hig-made-in.sp': 0,
      'hig-made-in.pl': -0.5,

      'ama-bat.h': 3.2,
      'ama-bat.m': 2.8,
      'ama-bat.l': -3,

      'ama-mec.h': 3,
      'ama-mec.m': 2.5,
      'ama-mec.l': -2,

      'ama-made-in.?': 0,
      'ama-made-in.de': 0.375,
      'ama-made-in.sp': 0,
      'ama-made-in.pl': -0.375,

      'low-bat.h': 3,
      'low-bat.m': 2.5,
      'low-bat.l': 2,

      'low-mec.h': 2.7,
      'low-mec.m': 2.4,
      'low-mec.l': 2,

      'low-made-in.?': 0,
      'low-made-in.de': 0.125,
      'low-made-in.sp': 0,
      'low-made-in.pl': -0.125,
    },

    featurePartialUtility(featureId, segmentId) {
      return get().basePreferences[`${segmentId}-${featureId}`];
    },

    valueDriverImportance(valueDriverId: string, segmentId: string) {
      const deltas = get()
        .features.map(
          toStruct({
            valueDriver: valueDriverOf,
            featureId: v => v,
          })
        )
        .reduce(toGroupsBy('valueDriver'), [])
        .map(([vd, vs]) => [
          vd,
          vs
            .map(to('featureId'))
            .map(f => get().featurePartialUtility(f, segmentId)),
        ])
        .map(([vd, utilities]) => [
          vd,
          Math.max(...utilities) - Math.min(...utilities),
        ]);

      const total = deltas.map(to(1)).reduce(toSum(), 0);
      const vd = deltas.find(ifValueOf(0, isEqualTo(valueDriverId)))[1];
      return vd / total;
    },

    setBasePartialUtility(featureId: string, segmentId: string, value: number) {
      set(
        produce<IOffers>(s => {
          s.basePreferences[`${segmentId}-${featureId}`] = value;
        })
      );
    },

    utilityCoefficientsByCountry: {
      'italy-hig-bat.h': 1,
    },

    readUtilityCoefficient(countryId, featureId, segmentId) {
      return (
        get().utilityCoefficientsByCountry[
          [countryId, segmentId, featureId].join('-')
        ] || 1
      );
    },

    setUtilityCoefficient(countryId, featureId, segmentId, value) {
      set(
        produce<IOffers>(s => {
          s.utilityCoefficientsByCountry[
            `${countryId}-${segmentId}-${featureId}`
          ] = value;
        })
      );
    },

    featurePartialUtilityInCountry(countryId) {
      return (featureId, segmentId) => {
        const c = get().readUtilityCoefficient(countryId, featureId, segmentId);
        return get().featurePartialUtility(featureId, segmentId) * c;
      };
    },

    segmentsIds: ['hig', 'ama', 'low'],
    segments: [
      { country: 'italy', id: 'hig', size: 1_500_000 * 0.1 },
      { country: 'italy', id: 'ama', size: 1_500_000 * 0.3 },
      { country: 'italy', id: 'low', size: 1_500_000 * 0.6 },
      { country: 'poland', id: 'hig', size: 1_500_000 * 0.05 },
      { country: 'poland', id: 'ama', size: 1_500_000 * 0.1 },
      { country: 'poland', id: 'low', size: 1_500_000 * 0.85 },
      { country: 'germany', id: 'hig', size: 1_500_000 * 0.05 },
      { country: 'germany', id: 'ama', size: 1_500_000 * 0.1 },
      { country: 'germany', id: 'low', size: 1_500_000 * 0.85 },
      { country: 'spain', id: 'hig', size: 1_500_000 * 0.1 },
      { country: 'spain', id: 'ama', size: 1_500_000 * 0.3 },
      { country: 'spain', id: 'low', size: 1_500_000 * 0.6 },
    ],

    segmentSizeByCountry(country) {
      return get()
        .segments.filter(ifValueOf('country', isEqualTo(country)))
        .map((s, i, ss) => ({
          ...s,
          relativeSize: s.size / ss.map(to('size')).reduce(toSum(), 0),
        }));
    },

    incrementSegmentSize(countryId: string, segmentId: string) {
      set(
        produce<IOffers>(s => {
          const seg = s.segments.find(
            s => s.country === countryId && s.id === segmentId
          );
          if (!seg) return;
          seg.size += 100_000;
        })
      );
    },

    decrementSegmentSize(countryId: string, segmentId: string) {
      set(
        produce<IOffers>(s => {
          const seg = s.segments.find(
            s => s.country === countryId && s.id === segmentId
          );
          if (!seg) return;
          seg.size = Math.max(0, seg.size - 100_000);
        })
      );
    },

    offers: [
      {
        brand: 'ROSSO',
        features: ['bat.h', 'mec.h', 'made-in.de'],
        price: 650,
        historicalPrice: 650,
        brandAwarenessInvestment: 1_000,
      },
      {
        brand: 'BLU',
        features: ['bat.m', 'mec.m', 'made-in.?'],
        price: 380,
        historicalPrice: 380,
        brandAwarenessInvestment: 1_500,
      },
      {
        brand: 'VERDE',
        features: ['bat.l', 'mec.l', 'made-in.pl'],
        price: 220,
        historicalPrice: 220,
        brandAwarenessInvestment: 2_000,
      },
    ],

    offersToEvaluate() {
      return get().offers.map(o => ({
        ...o,
        brandAwarenessIndex: brandAwarenessIndex()(o),
      }));
    },

    launchBrand(offer: Offer) {
      set(
        produce<IOffers>(s => {
          s.offers.push(offer);
        })
      );
    },

    withdrawBrand(name) {
      set(
        produce<IOffers>(s => {
          s.offers = s.offers.filter(o => o.brand !== name);
        })
      );
    },

    offerEvaluationsByPeriod: {},

    findOfferEvaluations(country) {
      return period => {
        const evaluations =
          get().offerEvaluationsByPeriod[[country, period].join('.')] || [];
        return evaluations.map(e => ({
          ...e,
          country,
          period,
        }));
      };
    },

    evaluateOffers(period, country, offers) {
      set(
        produce<IOffers>(s => {
          s.offerEvaluationsByPeriod[[country, period].join('.')] =
            evaluateMarketPreference(
              get().featurePartialUtilityInCountry(country)
            )(() => get().segmentSizeByCountry(country))(() => offers);
        })
      );
    },
  };
};

export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>,
  get: GetState<E extends T ? E : E & T>
) => T;

export const useOffers = create(offersStoreFactory);
