import { ILogger } from '../concepts/logger';
import { StoreSlice } from '../utils/store-slice';

interface IPrivate {}

export const create: StoreSlice<ILogger & IPrivate> = (set, get) => {
  return {
    log: (msg: string) => {
      console.log(`[msg] ${msg}`);
    },
    error: (reason: string) => {
      console.log(`[msg! ${reason}`);
    },
  };
};
