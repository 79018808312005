import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';
import { log, to, toGroupsBy, toStruct, toUniq } from '../../../../lib/say-it';
import { useOffers } from '../store';
import ColorScheme from 'color-scheme';

const colors = ['red', 'blue', 'green'];

export const MultiPeriodChart: React.FC<{ country: string }> = props => {
  const toEvaluations = useOffers(s => s.findOfferEvaluations(props.country));
  const evaluateOffers = useOffers(s => s.evaluateOffers);
  const incrementSegmentSize = useOffers(s => s.incrementSegmentSize);
  const decrementSegmentSize = useOffers(s => s.decrementSegmentSize);
  const offersToEvaluate = useOffers(s => s.offersToEvaluate);

  useEffect(() => {
    let timeout = null;
    let period = 1;

    const tick = () => {
      if (period <= 8) {
        switch (props.country) {
          case 'italy': {
            incrementSegmentSize(props.country, 'hig');
            break;
          }

          case 'poland':
            incrementSegmentSize(props.country, 'hig');
            incrementSegmentSize(props.country, 'ama');
            break;

          case 'germany':
            if (period % 3) {
              incrementSegmentSize(props.country, 'ama');
              incrementSegmentSize(props.country, 'ama');
            } else {
              decrementSegmentSize(props.country, 'ama');
              decrementSegmentSize(props.country, 'ama');
            }
        }

        evaluateOffers(period.toString(), props.country, offersToEvaluate());
        period++;
        setTimeout(tick, 1000);
      }
    };

    tick();

    return () => clearTimeout(timeout);
  }, [props.country]);

  const evaluations = Array(8)
    .fill(0)
    .map((v, i) => i + 1)
    .map(p => p.toString())
    .map(p => toEvaluations(p))
    .flat();

  const brands = evaluations
    .map(to('offer'))
    .map(to('brand'))
    .reduce(toUniq(), []);

  const data = evaluations.reduce((data, e) => {
    const datum = data.find(d => d.period == e.period);
    if (datum) {
      datum[`brand-${e.offer.brand}-revenue-shares`] = e.shareOfRevenues;
      datum[`brand-${e.offer.brand}-volumes`] = e.volumes;
    } else {
      data.push({
        period: e.period,
        [`brand-${e.offer.brand}-revenue-shares`]: e.shareOfRevenues,
        [`brand-${e.offer.brand}-volumes`]: e.volumes,
      });
    }
    return data;
  }, []);

  console.log(evaluations);
  console.log(brands);
  console.log(data);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 600 }}>
      <div>
        <h5>Share of Revenues per Period</h5>
        <BarChart width={500} height={500} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Legend />
          {brands.map((b, i) => {
            return (
              <Bar
                dataKey={`brand-${b}-revenue-shares`}
                stackId="a"
                fill={colors[i]}
              />
            );
          })}
        </BarChart>
      </div>
      <div>
        <h5>Demand Volumes per Period</h5>
        <BarChart width={500} height={500} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Legend />
          {brands.map((b, i) => {
            return <Bar dataKey={`brand-${b}-volumes`} fill={colors[i]} />;
          })}
        </BarChart>
      </div>
    </div>
  );
};

// scenari di mercato
// -> mercato stabile
// -> mercato volatile
// -> mercato in crescita
// -> mercato (solo prodotto) ad alta volatilità
// -> mercato (solo prodotto) a bassa volatilità

// -> simulare comportamento players in termini di ba e prezzo
// ->
