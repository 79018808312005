import { Offer } from '.';
import { ifValueOf, isEqualTo, to, toSum } from '../../../../lib/say-it';

const productPerformance =
  (slope: () => number) =>
  (totalUtility: () => number, slideCoff: () => number) => {
    return Math.exp(totalUtility() * slope()) * slideCoff();
  };

const slideCoff = (threshold: () => number) => (totalUtility: () => number) => {
  if (totalUtility() > 0) return 1;
  if (totalUtility() < threshold()) return 0;
  return (1 / -threshold()) * totalUtility() + 1;
};

const totalUtilityAfterPrice =
  (
    utilityBeforePrice: () => number,
    brandImageUtility: () => number,
    priceUtility: () => number
  ) =>
  () => {
    return utilityBeforePrice() + brandImageUtility() + priceUtility();
  };

const utilityBeforePrice =
  (
    utilities: (feature: string, segment: string) => number,
    correction: (segment: string) => number
  ) =>
  (offer: () => { features: string[]; segment: string }) => {
    const { features, segment } = offer();
    return features.reduce(
      (res, feature) => res + utilities(feature, segment),
      correction(segment)
    );
  };

const priceUtility =
  (utility: (segment: string) => number) =>
  (offer: () => { price: number; segment: string }) => {
    const { price, segment } = offer();
    return utility(segment) * price;
  };

const brandImageUtility =
  (utility: (segment: string) => number, shift: (segment: string) => number) =>
  (offer: () => { historicalPrice: number; segment: string }) => {
    const { historicalPrice, segment } = offer();
    return utility(segment) * historicalPrice + shift(segment);
  };

export type OfferEvaluation = {
  offer: OfferToEvaluate;
  segments: Record<string, SegmentEvaluation>;
  volumes: number;
  revenues: number;
  shareOfVolumes: number;
  shareOfRevenues: number;
};

type SegmentEvaluation = {
  performance: number;
  preference: number;
  share: number;
  volumes: number;
};

export type OfferToEvaluate = {
  brand: string;
  features: string[];
  price: number;
  historicalPrice: number;
  brandAwarenessInvestment: number;
  brandAwarenessIndex: number;
};

export const evaluateMarketPreference =
  (featuresPartialUtilities: (featureId: string, segmentId) => number) =>
  (segments: () => { id: string; size: number }[]) =>
  (offers: () => Array<OfferToEvaluate>): OfferEvaluation[] => {
    const performances: OfferEvaluation[] = offers().map(offer => {
      return {
        offer,
        segments: segments().reduce((acc, segment) => {
          const o = { ...offer, segment: segment.id };

          const perf = productPerformance(() => 0.6);
          const pU = priceUtility(pricePartialUtilities);
          const iU = brandImageUtility(
            brandImagePartialUtilities,
            brandImageUtilityShift
          );
          const bU = utilityBeforePrice(featuresPartialUtilities, corrections);
          const tU = totalUtilityAfterPrice(
            () => bU(() => o),
            () => iU(() => o),
            () => pU(() => o)
          );
          const slide = slideCoff(() => -2.5);

          return {
            ...acc,
            [segment.id]: {
              performance: perf(tU, () => slide(tU)),
              preference: 0,
              share: 0,
              volumes: 0,
            },
          };
        }, {}),
        volumes: 0,
        revenues: 0,
        shareOfRevenues: 0,
        shareOfVolumes: 0,
      };
    });

    // preference
    segments().forEach(s => {
      const totalPerf = performances.reduce(
        (sum, p) => sum + p.segments[s.id].performance,
        0
      );
      for (const p of performances) {
        p.segments[s.id].preference = p.segments[s.id].performance / totalPerf;
      }
    });

    // share
    segments().forEach(s => {
      for (const p of performances) {
        p.segments[s.id].share =
          p.segments[s.id].preference * p.offer.brandAwarenessIndex;
      }
    });

    // segment volumes
    segments().forEach(s => {
      for (const p of performances) {
        p.segments[s.id].volumes = p.segments[s.id].share * s.size;
      }
    });

    // offer volumes
    for (const p of performances) {
      p.volumes = Object.keys(p.segments)
        .map(k => p.segments[k])
        .map(to('volumes'))
        .reduce(toSum(), 0);
    }

    // offer revenues
    for (const p of performances) {
      p.revenues = p.volumes * p.offer.price;
    }

    // offer shareOfVolumes
    const totalVolumes = performances.map(to('volumes')).reduce(toSum(), 0);
    for (const p of performances) {
      p.shareOfVolumes = p.volumes / totalVolumes;
    }

    // offer shareOfRevenues
    const totalRevenues = performances.map(to('revenues')).reduce(toSum(), 0);
    for (const p of performances) {
      p.shareOfRevenues = p.revenues / totalRevenues;
    }

    return performances;
  };

const pricePartialUtilities = (segment: string) => {
  switch (segment) {
    case 'hig':
      return -0.025;
    case 'ama':
      return -0.037;
    case 'low':
      return -0.053;
  }
};

const brandImagePartialUtilities = (segment: string) => {
  switch (segment) {
    case 'hig':
      return 0.005;
    case 'ama':
      return 0.0038;
    case 'low':
      return 0.0025;
  }
};

const brandImageUtilityShift = (segment: string) => {
  switch (segment) {
    case 'hig':
      return -1.5;
    case 'ama':
      return -1;
    case 'low':
      return -0.5;
  }
};

const corrections = (segment: string) => {
  return 12;
};
