import { useReactTable } from '@tanstack/react-table';
import { price } from '../layout/formatters';
import { Interaction } from '../layout/interaction';
import { Param } from '../layout/param';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { Term } from '../term';

export const KnowTheMarket = () => (
  <>
    <SectionTitle id="know-the-market">Conosci il Mercato</SectionTitle>

    <p>
      Il mercato è suddiviso in <Param>3</Param> <Term lemma="segmenti" />:{' '}
      <Param>Fanatici Altospendenti</Param>, <Param>Amatori</Param> e{' '}
      <Param>Low Involved</Param>.
    </p>

    <p>
      <i>Inserire qui breve descrizione dei segmenti di mercato...</i>
    </p>

    <Interaction>
      <h4>Ordina Indagini di Mercato</h4>

      <p>
        Possono essere ordinate <Param>3</Param> tipologie di{' '}
        <Term lemma="indagini di mercato" />
      </p>

      <p>
        <i>Inserire descrizione dettagliata delle 3 tipologia di indagine...</i>
      </p>

      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr
            style={{
              verticalAlign: 'top',
              lineHeight: '1em',
            }}
          >
            <>
              <th style={{ width: '10%' }}>Paese</th>
              <th style={{ width: '10%' }}>Tipo di Indagine</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Costo</th>
              <th style={{ width: '10%', textAlign: 'right' }}>
                Conferma Ordine
              </th>
            </>
          </tr>
        </thead>
        <tbody>
          {[
            { country: 'Germania' },
            { country: 'Italia' },
            { country: 'Francia' },
            { country: 'Polonia' },
          ].map((info, i) => (
            <tr key={i} style={{ background: i % 2 ? '#eee' : 'white' }}>
              <td>{info.country}</td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                <select>
                  <option id="low">Base</option>
                  <option id="low">Media</option>
                  <option id="low">Alta</option>
                </select>
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {price(5000)}
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                <button>Ordina Indagine</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Interaction>

    <Interaction>
      <span>
        mercato x dim. complessiva del mercato, numero di brand, elenco di brand
        con caratteristiche, prezzo e volumi di vendita
      </span>
    </Interaction>

    <Report>
      <h4 id="market-infos">Informazioni sul Mercato</h4>
      <i>Inserire qui tabelle riassuntive dei dati di mercato</i>
    </Report>

    <Report>
      <h4 id="conjoint-analysis">Conjoint Analysis</h4>
    </Report>
  </>
);

// import { useTable } from 'react-table';
// import { GetState, SetState } from 'zustand';

// const globalLevelId = (driverId: string, localLevelId: string) =>
//   [driverId, localLevelId].join('-');

// const isALevelOfDriver = (driverId: string) => (globalLevelId: string) => {
//   return globalLevelId.split('-')[0] === driverId;
// };

// interface IDriverSet {
//   addDriver(driverId: string): void;
//   addDriverLevel(levelId: string, afterLevelId?: string): void;
//   setDriverLevelCost(driverId: string, levelId: string, cost: number): void;

//   driverDeltaUtility(driverId: string): number;
// }

// interface ISegment {
//   recordSegmentPerceivedUtility(
//     segmentId: string,
//     driverId: string,
//     scores: { [levelId: string]: number }
//   ): void;
// }

// interface IProduct {
//   recordProductFeatures(
//     productId: string,
//     features: { [driverId: string]: string }
//   ): void;
//   recordProductPrice(productId: string, price: number): void;
// }

// interface IConjointAnalysis {
//   /** commands */
//   setDriverSet(driverSet: IDriverSet): void;
//   setSegment(id: string, segment: ISegment): void;
//   setProduct(id: string, product: IProduct): void;

//   /** queries */
//   productMarketShareBySegment(productId: string, segmentId: string): number;
// }

// interface IPrivate {
//   marketShares: { [id: string]: number };
// }

// const useConjointAnalysis = create(
//   (
//     set: SetState<IConjointAnalysis & IPrivate>,
//     get: GetState<IConjointAnalysis & IPrivate>
//   ) => ({
//     /** state */
//     marketShares: {},

//     setDriverSet(driverSet: IDriverSet) {},

//     setSegment(id: string, segment: ISegment) {},

//     setProduct(id: string, product: IProduct) {},

//     /** queries */
//     productMarketShareBySegment(productId: string, segmentId: string) {
//       return get().marketShares[`${segmentId}.${productId}`] || 0;
//     },
//   })
// );

// const ConjointAnalysis = () => {
//   return null;
// };
