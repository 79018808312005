import React from 'react';
import { makeComponent } from '../../fanes';

export type ContainerProps = {
  children?: React.ReactNode | React.ReactNode[];
};

export const ContainerToken = 'layout.container';

export const Container = makeComponent<ContainerProps>(ContainerToken);
