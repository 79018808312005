import { useState } from 'react';
import { useOffers } from '../store';

export const LaunchBrand = () => {
  const launchBrand = useOffers(s => s.launchBrand);
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [historicalPrice, setHistoricalPrice] = useState(0);
  const [brandAwarenessInvestment, setBrandAwarenessInvestment] = useState(0);
  const [battery, setBattery] = useState('');
  const [mechanics, setMechanics] = useState('');
  const [madeIn, setMadeIn] = useState('');

  return (
    <div>
      <div>
        brand name: <br />
        <input value={name} onChange={e => setName(e.target.value)} />
      </div>

      <div>
        features: <br />
        <table>
          <tbody>
            <tr>
              <td>Battery:</td>
              <td>
                <select
                  onChange={e => {
                    setBattery(e.target.value);
                  }}
                  value={battery}
                >
                  <option>--</option>
                  <option value={'h'}>H</option>
                  <option value={'m'}>M</option>
                  <option value={'l'}>L</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Mechanics:</td>
              <td>
                <select
                  onChange={e => {
                    setMechanics(e.target.value);
                  }}
                  value={mechanics}
                >
                  <option>--</option>
                  <option value={'h'}>H</option>
                  <option value={'m'}>M</option>
                  <option value={'l'}>L</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Made In:</td>
              <td>
                <select
                  onChange={e => {
                    setMadeIn(e.target.value);
                  }}
                  value={madeIn}
                >
                  <option>--</option>
                  <option value={'?'}>?</option>
                  <option value={'de'}>1° DE</option>
                  <option value={'sp'}>2° SP</option>
                  <option value={'pl'}>3° PL</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        price: <br />
        <input value={price} onChange={e => setPrice(Number(e.target.value))} />
      </div>

      <div>
        historical price: <br />
        <input
          value={historicalPrice}
          onChange={e => setHistoricalPrice(Number(e.target.value))}
        />
      </div>

      <div>
        b.a. investment: <br />
        <input
          value={brandAwarenessInvestment}
          onChange={e => setBrandAwarenessInvestment(Number(e.target.value))}
        />
      </div>

      <button
        disabled={
          !name ||
          !price ||
          !battery ||
          !mechanics ||
          !madeIn ||
          !historicalPrice ||
          !brandAwarenessInvestment
        }
        onClick={() =>
          launchBrand({
            brand: name,
            price,
            features: [
              `bat.${battery}`,
              `mec.${mechanics}`,
              `made-in.${madeIn}`,
            ],
            historicalPrice,
            brandAwarenessInvestment,
          })
        }
      >
        Launch
      </button>
    </div>
  );
};
