import React from 'react';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';

export const KnowThePlant = () => (
  <>
    <SectionTitle id="know-the-product">Conosci la Fabbrica</SectionTitle>

    <Report>
      <h4 id="cost-per-unit-raw-material">Elenco tecnologie produttive</h4>
      <span>
        Descrizione delle due tecnologie produttive, con approfondimento solo
        sul mio costo
      </span>
    </Report>
  </>
);
